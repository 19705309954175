<template>
  <div class="loginwrap">
    <div class="rightform">
      <div class="formbox">
        <el-row>
          <el-col class="api-center" :xs="18" :sm="15" :md="13" :lg="11">
            <h2 class="api-title">重置密码</h2>
            <p class="api-text">
              密码有效期五分钟，请及时设置
            </p>
            <el-form ref="loginFormRef" :rules="loginFormRules" class="loginform" :model="loginForm">
              <el-form-item prop="verifycode">
                <el-input
                  placeholder="邮件验证码"
                  v-model="loginForm.verifycode"
                  @keyup.enter.native="handellogoForm('loginFormRef')"
                >
                  <i slot="prefix" class="iconfont iconyanzhengma"></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="pass">
                <el-input
                  placeholder="新密码"
                  type="password"
                  v-model="loginForm.pass"
                  @keyup.enter.native="handellogoForm('loginFormRef')"
                >
                  <i slot="prefix" class="iconfont iconmima1"></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="repass">
                <el-input
                  placeholder="重新输入新密码"
                  type="password"
                  v-model="loginForm.repass"
                >
                  <i slot="prefix" class="iconfont iconmima1"></i>
                </el-input>
              </el-form-item>
              <el-form-item class="api-forget">
                <span class="fr">
                  已有账号，请
                  <router-link :to="{ name:'login' }" tag="a">登录</router-link>
                </span>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  style="width:100%;background:#2F95E3;border:none;"
                  v-loading="btnLoading"
                  @click="handellogoForm('loginFormRef')"
                >提交</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { forgetResetRequest } from "@/api/user.js";
export default {
  data() {
    const validatePass = (rule, value, callback) =>{
      if (this.loginForm.rePass !== '') {
        this.$refs.loginFormRef.validateField('rePass');
      }
      callback();
    }
    const validatePass2 = (rule, value, callback) =>{
      if (value !== this.loginForm.pass) {
        return callback(new Error('两次输入密码不一致!'));
      }
      callback();
    }
    return {
      btnLoading: false,
      loginForm: {
        pass: '',
        repass:'',
        verifycode:''
      },
      loginFormRules: {
        verifycode:[
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        pass:[
           { required: true, message: '请输入密码', trigger: 'blur' },
           { min: 6, max: 16, message: "长度在 6 到 16 个字符", trigger: "blur" },
           { validator: validatePass, trigger: "blur" }
        ],
        repass:[
          { required: true, message: '请再一次输入密码', trigger: 'blur' },
           { min: 6, max: 16, message: "长度在 6 到 16 个字符", trigger: "blur" },
           { validator: validatePass2, trigger: "blur" }
        ]
        }
    };
  },
  methods: {
     async registerFormSubmit() {
      const result = await forgetResetRequest({
        verifycode:this.loginForm.verifycode,
        pass: this.$md5(this.loginForm.pass),
        repass: this.$md5(this.loginForm.repass)
      });
      if (result.status === 200) {
         this.$message({
          showClose: true,
          message: result.message,
          type: "success"
        });
        this.$router.push({ name: "login" });
      } else {
        this.$message({
          showClose: true,
          message: result.message,
          type: "error"
        });
        this.btnLoading = false;
      }
      this.btnLoading = false;
    },
    handellogoForm(formName) {
      this.$refs[formName].validate(valid => {
        this.btnLoading = true;
        try {
          if (!valid) {
            this.btnLoading = false;
            return;
          }
          this.registerFormSubmit();
        } catch (err) {
          this.$message({
            showClose: true,
            message: "发送失败",
            type: "error"
          });
          this.btnLoading = false;
          console.log(err);
        }
      });
    },
  }
};
</script>
<style lang="less" scoped>
/deep/html,
/deep/body,
/deep/#app {
  margin: 0;
  padding: 0;
  height: auto;
  min-height: 100%;
}
.loginwrap {
  height: 100%;
  min-height: 100%;
  background: url("../../../assets/imgs/loginbg.png");
  background-size: 100% 100%;
  display: flex;
  .leftimgbox {
    flex: 1;
    box-sizing: border-box;
    padding: 50px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  .rightform {
    flex: 1;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    .formbox {
      width: 100%;
      padding: 20px;
      & > .el-row {
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
    }
    /deep/.api-center {
      background: #fff;
      padding: 50px 7%;
      -webkit-box-shadow: 0px 0px 10px #c7ddec;
      box-shadow: 0px 0px 17px #c7ddec;
      border-radius: 10px;
      .api-title {
        font-size: 22px;
        line-height: 50px;
        text-align: center;
        margin-bottom: 10px;
      }
      .api-text {
        font-size: 17px;
        margin-bottom: 40px;
        text-align: center;
        line-height: 36px;
      }
      /deep/.el-input__prefix {
        left: 12px;
      }
      /deep/.el-input--prefix .el-input__inner {
        padding-left: 35px;
      }
      .api-forget {
        margin: -10px 0 15px 0;
        font-size: 13px;
        a {
          color: #2f95e3;
          text-decoration: none;
        }
      }
    }
  }
}
</style>